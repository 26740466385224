<template>
    <b-container fluid class="h-100">
        <b-row class="h-100" align-v="center" align-h="center">
            <b-col cols="12" sm="8" md="6" lg="4" style="max-width: 500px;">
                <img class="pb-4 text-center" style="width: 90%;padding-left: 5%" src="./../assets/gd_full.png"
                     alt="Global Delivery">
                <b-card title="Авторизация">
                    <p v-if="description">{{description}}</p>
                    <form>
                        <b-form-group label="Логин">
                            <b-form-input type="text" id="emailInput" placeholder="Введите логин" v-model="email"/>
                        </b-form-group>
                        <b-form-group label="Пароль">
                            <b-form-input type="password" id="passwordInput" placeholder="Введите пароль"
                                          v-model="password"/>
                        </b-form-group>
                        <b-button type="submit" id="button" variant="primary" @click="onSubmit">Войти</b-button>
                    </form>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    export default {
        name: "Login",
        created() {
            localStorage.removeItem("jwt");
            localStorage.removeItem("role");
        },
        methods: {
            onSubmit(evt) {
                evt.preventDefault();
                this.$http.post(this.$root.apiUrl + '/api/login',
                    {
                        password: this.password,
                        username: this.email,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(response => {
                    if ('access_token' in response.body) {
                        localStorage.setItem('jwt', response.body.access_token);
                        localStorage.setItem('user', this.email);
                        this.description = '';
                        this.password = '';
                        this.$router.push('/');
                    } else {
                        this.description = "Неправильный логин или пароль"
                    }
                }, response => {
                    if (response.status === 401) {
                        this.description = "Неправильный логин или пароль"
                    } else {
                        this.description = "Ошибка соединения с сервером"
                    }
                });
            }
        },
        data() {
            return {
                email: '',
                password: '',
                state: null,
                description: ''
            }
        }
    }
</script>

<style scoped>
    #button {
        width: 100%;
        background-color: #6c757d;
        border-color: #6c757d;
    }
</style>
